<template>
  <div class="create_job container">
    <JobViewJobCustomerDetails
      jobId="new"
    />
  </div>
</template>

<script>
import JobViewJobCustomerDetails from "@/components/jobs/JobViewJobCustomerDetails";

export default {
  name: "CreateJob",
  components: {
    JobViewJobCustomerDetails
  },
  setup (){

    function createJob(){

    }
  }
}
</script>

<style scoped>

</style>
